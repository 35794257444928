import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
// import { Provider } from 'mobx-react';
// import ThemeProvider from '@src/components/ContextProvider';
import Logger from '@utils/logger';
import firebase from '@utils/firebase';

import HomePage from './pages/HomePage';
import ArticlesPage from './pages/ArticlesPage';
import SummaryArticle from './pages/ArticlesPage/SummaryArticle';
import MainnetArticle from './pages/ArticlesPage/MainnetArticle';
import EcosystemArticle from './pages/ArticlesPage/EcosystemArticle';
import LanguageProvider from './providers/LanguageProvider';
import { ProviderStore, StoreInstance, useStores } from './stores';
import HoneyWoodPage from './pages/HoneyWoodPage';
import MobileForInstagramPage from './pages/MobileForInstagramPage';
import PrivacyPage from './pages/PrivacyPage';
import MobilePaymentPage from './pages/MobilePayment';

const App = () => {
  const stores = useStores();
  const [isInstagram, setInstagram] = useState({ state: false, queries: '' });
  useEffect(() => {
    /* REACT_APP_STAGE logic */
    // Global site tag (gtag.js) - Google Analytics
    // if (process.env.REACT_APP_STAGE === 'production') {
    //   const gtagScript = document.createElement('script');
    //   gtagScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-R2XCNQVMZ3');
    //   document.head.appendChild(gtagScript);

    //   const gtagScript2 = document.createElement('script');
    //   gtagScript2.innerHTML =
    //     "window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'G-R2XCNQVMZ3');";
    //   document.head.appendChild(gtagScript2);
    // }
  }, []);

  useEffect(() => {
    const infoLocation = window.location;
    if (navigator.userAgent && navigator.userAgent.includes('Instagram') && window.location.href.includes('invitedby')) {
      // open link in instagram;
      const invitedBy = window.location.href.split('https://moneta.today')[1];
      // const newDeepLink = `https://app.moneta.today${invitedBy}`;
      const path = `https://monetatoday.page.link/?link=https://app.moneta.today${invitedBy}&apn=moneta.today&isi=1605931536&ibi=moneta.today&st=Welcome+my+friend&sd=Receive+rewards+everyday+by+installing+this+app&si=https://honeywood.io/favicon/ms-icon-310x310.png&utm_campaign=Instagram+link&utm_medium=cpc&utm_source=instagram`;
      (async () => {
        try {
          const resultWrote = await firebase.writeData({ data: {
            userAgent: navigator.userAgent,
            href: window.location.href,
            protocol: window.location.protocol,
            invitedBy, 
            isInstagram: true,
            path,
          } });
          console.log('resultWrote >> ', resultWrote);
        } catch (err) {
          Logger.error(err);
        }
      })()
      
      if (stores.browserInfo.isMobile) {
        // stores.setInstagramMobile(true);
        // setInstagram({state: true, queries: invitedBy });
        window.location.href = path;
      }

      Logger.infoMainNotify({ nameNotify: 'info notify', section: 'user-agent', meta: { infoLocation, isInstagramBrowser: true }, useInProduction: true });
    } else {
      Logger.infoMainNotify({ nameNotify: 'info notify', section: 'user-agent', meta: { infoLocation, userAgent: navigator.userAgent, isInstagramBrowser: false }, useInProduction: true });
    }
  }, []);

  return (
    <ProviderStore value={StoreInstance}>
      <LanguageProvider>
        <BrowserRouter>
          <Routes>
            {isInstagram.state ? (
              <Route path="*" element={<MobileForInstagramPage queries={isInstagram.queries} />} />
            ) : (
              <>
                <Route path="/" element={<HomePage />} exact />
                <Route path="/mobile/payment" element={<MobilePaymentPage />} exact />
                <Route path="/honeywood" element={<HoneyWoodPage />} exact />
                <Route path="/articles/moneta-today-ecosystem" element={<SummaryArticle />} exact />
                <Route
                  path="/articles/ecosystem-or-metaverse"
                  element={<EcosystemArticle />}
                  exact
                />
                <Route path="/articles/moneta-today-mainnet" element={<MainnetArticle />} exact />
                <Route path="/articles" element={<ArticlesPage />} />
                <Route path="/articles/*" element={<ArticlesPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      </LanguageProvider>
    </ProviderStore>
  );
};

export default App;
